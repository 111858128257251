.top_select[data-v-1ed7107e] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon[data-v-1ed7107e] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.item_icon i[data-v-1ed7107e] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 2px;
}
.item_icon p[data-v-1ed7107e] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
.text_width[data-v-1ed7107e] {
  width: 52px;
  display: inline-block;
}
.icon-tiaoguo1[data-v-1ed7107e] {
  color: #17a2b8;
}
.style_form .el-form-item[data-v-1ed7107e] {
  margin-bottom: 10px;
}
.icon-a-xinjianzidingyichicun21x1[data-v-1ed7107e] {
  font-size: 15px;
  margin-right: 2px;
}
.icon-jiantou-you[data-v-1ed7107e] {
  font-weight: 600;
}
.div_p[data-v-1ed7107e] {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.div_p[data-v-1ed7107e]:last-child {
  border-bottom: none;
}
.auto_content_size[data-v-1ed7107e] {
  width: 91%;
  margin-left: 10px;
}